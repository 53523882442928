@if (showBanner$ | ngrxPush) {
  <schaeffler-banner-text
    [text]="bannerText$ | ngrxPush"
    [showFullText]="showFullText$ | ngrxPush"
    [truncateSize]="truncateSize$ | ngrxPush"
    [buttonText]="bannerButtonText$ | ngrxPush"
    [bannerIcon]="bannerIcon$ | ngrxPush"
    (closeBanner)="closeBanner()"
    (toggleFullText)="toggleFullText()"
  ></schaeffler-banner-text>
}
