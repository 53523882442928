<div class="flex" *transloco="let t">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>{{ t('localeSelectLabel') }}</mat-label>
    <mat-select
      hideSingleSelectionIndicator
      [formControl]="localeSelectControl"
      (selectionChange)="onLocaleSelectionChange($event.value)"
    >
      @for (lang of availableLocales; track lang) {
        <mat-option [value]="lang.id"> {{ lang.label }}</mat-option>
      }
    </mat-select>
    <mat-hint>{{ t(hintText || 'localeSelectHint') }}</mat-hint>
  </mat-form-field>
  <div class="flex h-[65px] items-center pl-3">
    <mat-icon
      color="primary"
      class="cursor-help"
      #tooltip="matTooltip"
      [matTooltip]="t(tooltipText || 'localeSelectTooltip')"
      matTooltipPosition="after"
      matTooltipClass="!max-w-[400px]"
      (click)="tooltip.toggle()"
    >
      info_outline
    </mat-icon>
  </div>
</div>
