<!-- start header -->
<mat-toolbar
  class="z-10 h-[60px] border-b border-border !pl-2"
  *transloco="let t"
>
  @if (hasSidebarLeft) {
    <button
      type="button"
      mat-icon-button
      disableRipple
      class="text-medium-emphasis"
      (click)="drawer.toggle(); sidenavOpen = !sidenavOpen"
    >
      @if (sidenavOpen) {
        <mat-icon [@sidenavToggleAnimation]> arrow_back </mat-icon>
      } @else {
        <mat-icon [@sidenavToggleAnimation]>menu</mat-icon>
      }
    </button>
  }
  <span
    class="inline-block truncate pr-2 text-subtitle-1 text-medium-emphasis outline-none sm:pr-3 sm:text-h6"
    [class.ml-1]="hasSidebarLeft"
    [class.ml-2]="!hasSidebarLeft"
    [class.cursor-pointer]="appTitleLink && !sidenavOpen"
    [routerLink]="appTitleLink && !sidenavOpen ? appTitleLink : undefined"
  >
    @if (sidenavOpen) {
      <span>
        {{ t('shellSidenavHeading') }}
      </span>
    } @else {
      <span>{{ appTitle }}</span>
    }
  </span>
  <div class="content-wrapper flex flex-auto justify-end">
    <ng-content select="[headerInlineContent]"></ng-content>
  </div>
  <div class="schaeffler-header-logo"></div>
</mat-toolbar>
<!-- end header -->

<!-- start content container -->
<mat-sidenav-container class="flex h-full flex-col overflow-hidden">
  <!-- start left sidebar -->
  <mat-sidenav
    #drawer
    class="!w-[400px] !max-w-[90%] pt-[60px]"
    fixedInViewport
    mode="over"
    [(opened)]="sidenavOpen"
    [autoFocus]="false"
  >
    @if (userName) {
      <schaeffler-user-panel
        [userName]="userName"
        [userImageUrl]="userImageUrl"
      ></schaeffler-user-panel>
    }
    <mat-divider></mat-divider>
    <ng-content select="[sidenavBody]"></ng-content>
  </mat-sidenav>
  <!-- end left sidebar -->

  <!-- start content -->
  <mat-sidenav-content
    #sidenavContent
    class="flex-col pt-[60px]"
    [ngClass]="footerFixed ? 'footer-fixed' : 'footer-not-fixed'"
    (scroll)="handleContentScroll($event)"
  >
    <!-- start main content -->
    <div class="content-wrapper main-content-wrapper">
      <ng-content select="[mainContent]"></ng-content>
    </div>
    <!-- end main content -->

    <!-- start footer -->
    @if (hasFooter || footerLinks?.length) {
      <footer
        class="flex flex-col items-center justify-between gap-2 lg:flex-row"
      >
        <div class="content-wrapper p-2">
          <ng-content select="[footerContent]"></ng-content>
        </div>
        <div
          class="flex shrink-0 flex-col items-center justify-center md:flex md:flex-row"
        >
          <div class="mx-4 flex flex-row flex-wrap sm:mx-0">
            @if (footerLinks?.length) {
              @for (
                footerLink of footerLinks;
                track footerLink;
                let i = $index
              ) {
                @if (footerLink.external) {
                  <a
                    [href]="footerLink.link"
                    target="_blank"
                    rel="noopener noreferrer"
                    (click)="handleClick(footerLink, $event)"
                    class="footer-link-external py-1 text-caption leading-4 text-medium-emphasis"
                  >
                    {{ footerLink.title }}
                  </a>
                }
                @if (!footerLink.external) {
                  <div
                    [routerLink]="footerLink.link || undefined"
                    (click)="handleClick(footerLink, $event)"
                    class="footer-link-internal inline-block cursor-pointer py-1 text-caption leading-4 text-medium-emphasis outline-none"
                  >
                    {{ footerLink.title }}
                  </div>
                }
                @if (i !== footerLinks.length - 1 || appVersion) {
                  <span
                    class="separator mx-1 py-1 text-caption leading-4 text-medium-emphasis"
                    >|</span
                  >
                }
              }
            }
            @if (appVersion) {
              <span
                id="version"
                class="py-1 text-caption leading-4 text-medium-emphasis"
              >
                {{ appVersion }}
              </span>
            }
          </div>
          <div class="schaeffler-footer-logo m-3"></div>
        </div>
      </footer>
    }
    <!-- end footer -->
  </mat-sidenav-content>
  <!-- end content -->
</mat-sidenav-container>
<!-- end content container -->
