@if (breadcrumbs && breadcrumbs.length) {
  <div class="flex items-center pb-2 text-caption text-medium-emphasis">
    @for (breadcrumb of breadcrumbs; track i; let i = $index) {
      @if (showItemBeforeTruncateMenu(i)) {
        <schaeffler-breadcrumbs-item
          [breadcrumb]="breadcrumb"
          [hasFollowerBreadcrumb]="i < breadcrumbs.length - 1"
        ></schaeffler-breadcrumbs-item>
      }
    }
    @if (showTruncation()) {
      @if (showTruncateMenu()) {
        <span
          [matMenuTriggerFor]="breadcrumbsTruncateMenu"
          class="flex cursor-pointer items-center rounded bg-secondary-variant px-2"
        >
          <mat-icon class="!h-[auto] !w-[auto] !text-[16px]"
            >more_horiz</mat-icon
          >
        </span>
        <mat-menu
          #breadcrumbsTruncateMenu="matMenu"
          class="!max-h-[260px] !w-[260px]"
        >
          @for (breadcrumb of breadcrumbs; track i; let i = $index) {
            <schaeffler-breadcrumbs-menu-item
              [breadcrumb]="breadcrumb"
            ></schaeffler-breadcrumbs-menu-item>
          }
        </mat-menu>
        <mat-icon class="mx-2 !h-[auto] !w-[auto] !text-[16px]"
          >chevron_right</mat-icon
        >
      }
      @for (breadcrumb of breadcrumbs; track i; let i = $index) {
        @if (showItemAfterTruncateMenu(i)) {
          <schaeffler-breadcrumbs-item
            [breadcrumb]="breadcrumb"
            [hasFollowerBreadcrumb]="i < breadcrumbs.length - 1"
          ></schaeffler-breadcrumbs-item>
        }
      }
    }
  </div>
}
