<div
  id="legal-pages"
  class="mx-auto w-full max-w-screen-md bg-surface-legacy p-4"
  *transloco="let t"
>
  <div class="-ml-4">
    <schaeffler-subheader
      [subheaderTitle]="t('goToHome')"
      (backButtonClicked)="navigate()"
      [hideLine]="true"
    ></schaeffler-subheader>
  </div>
  @if (
    (termsOfUse$ | ngrxPush) &&
    (lastPath$ | ngrxPush) === LegalRoutePath.TermsPath
  ) {
    <div [innerHTML]="termsOfUse$ | ngrxPush"></div>
  } @else {
    <div [innerHTML]="translationContent$ | ngrxPush"></div>
  }
</div>
