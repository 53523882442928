<div class="border-border flex flex-col px-4 pb-5" [class.border-b]="!hideLine">
  @if (breadcrumbs.length > 0) {
    <div class="pl-9 pr-5 pt-5">
      <schaeffler-breadcrumbs
        [breadcrumbs]="breadcrumbs"
        [truncateAfter]="truncateBreadcrumbsAfter"
      ></schaeffler-breadcrumbs>
    </div>
  }
  <div
    class="flex items-center gap-3"
    [ngClass]="{
      'flex-wrap': breakTitle
    }"
  >
    @if (showBackButton) {
      <mat-icon
        class="text-medium-emphasis flex-none cursor-pointer"
        (click)="clickBackButton()"
        >arrow_back</mat-icon
      >
    }

    <ng-content select="[subheaderStatusIcon]"></ng-content>
    <h5
      class="text-high-emphasis sm:!text-h5 flex flex-auto flex-row items-center truncate"
    >
      {{ subheaderTitle }}
      <ng-content select="[subheaderTitleContent]"></ng-content>
    </h5>
    <ng-content select="[subheaderInlineContent]"></ng-content>
  </div>
  <ng-content select="[subheaderBlockContent]"></ng-content>
</div>
