<div
  class="flex flex-col md:flex-row w-full items-center rounded-md border-[1px] py-2 px-3 border-info bg-info"
>
  <div class="flex">
    <mat-icon class="mr-4 align-middle self-center flex-shrink-0 text-icon-info"
      >info_outline
    </mat-icon>
    <div class="text-subtitle-2 text-info">
      {{ infoText }}
    </div>
  </div>
  <ng-content></ng-content>
</div>
