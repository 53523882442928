<div *transloco="let t">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>{{ t('languageSelectLabel') }}</mat-label>
    <mat-select
      hideSingleSelectionIndicator
      [formControl]="languageSelectControl"
      (selectionChange)="onLanguageSelectionChange($event.value)"
    >
      @for (lang of availableLanguages; track lang) {
        <mat-option [value]="lang.id"> {{ lang.label }}</mat-option>
      }
    </mat-select>
    <mat-hint>{{ t('languageSelectHint') }}</mat-hint>
  </mat-form-field>
</div>
