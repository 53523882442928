export enum LoadInstallation {
  radial = 'IDL_RADIAL_INSTALLATION_CODE',
  positiveAxial = 'IDL_AXIAL_POSITIVE_INSTALLATION_CODE',
  negativeAxial = 'IDL_AXIAL_NEGATIVE_INSTALLATION_CODE',
}

export enum InstallationMode {
  fixed = 'IC_FIXED',
  free = 'IC_FREE',
}
