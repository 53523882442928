@if (shouldDisplayBanner) {
  @if (experimentalDesign) {
    <schaeffler-alert
      [type]="alertType"
      [description]="infoText"
      [withIcon]="false"
      [actionText]="feedbackButtonText"
      (buttonClicked)="openSurveyDialog()"
    >
    </schaeffler-alert>
  } @else {
    <schaeffler-info-banner [infoText]="infoText">
      <div class="pt-2 md:pt-0 md:ml-auto">
        <button
          mat-raised-button
          class="!text-medium-emphasis hover:!text-link whitespace-nowrap"
          (click)="openSurveyDialog()"
        >
          <mat-icon>feedback</mat-icon> {{ feedbackButtonText }}
        </button>
      </div>
    </schaeffler-info-banner>
  }
}
