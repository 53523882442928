import { BearingType } from '../models';

export const bearingTypes: BearingType[] = [
  {
    id: 'IDO_RADIAL_ANGULAR_CONTACT_BALL_BEARING',
    imageUrl: 'RadialAngularContactBallBearing.bmp',
  },
  {
    id: 'IDO_RADIAL_BALL_BEARING',
    imageUrl: 'RadialBallBearing.bmp',
  },
  {
    id: 'IDO_RADIAL_ROLLER_BEARING',
    imageUrl: 'RadialRollerBearing.bmp',
  },
  {
    id: 'IDO_RADIAL_SELF_ALIGNING_BALL_BEARING',
    imageUrl: 'RadialSelfAligningBallBearing.bmp',
  },
  {
    id: 'IDO_RADIAL_SELF_ALIGNING_ROLLER_BEARING',
    imageUrl: 'RadialSelfAligningRollerBearing.bmp',
  },
  {
    id: 'IDO_RADIAL_TAPERED_ROLLER_BEARING',
    imageUrl: 'RadialTaperedRollerBearing.bmp',
  },
  {
    id: 'IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING',
    imageUrl: 'ThrustAngularContactBallBearing.bmp',
  },
  {
    id: 'IDO_THRUST_BALL_BEARING',
    imageUrl: 'ThrustBallBearing.bmp',
  },
  {
    id: 'IDO_THRUST_ROLLER_BEARING',
    imageUrl: 'ThrustRollerBearing.bmp',
  },
];
