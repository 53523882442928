<div *transloco="let t">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>{{
      t('shared.label.defaultSelect', {
        label: t('userSettings.measurementUnits.title.main')
      })
    }}</mat-label>
    <mat-select
      [value]="selectedOption"
      [compareWith]="compareOptions"
      (selectionChange)="onMeasurementUnitsSelectChange($event.value)"
    >
      @for (option of measurementUnitsOptions; track option) {
        <mat-option [value]="option">{{ t(option.text) }}</mat-option>
      }
    </mat-select>
    <mat-hint>{{ t('shared.hint.applicationReload') }}</mat-hint>
  </mat-form-field>
</div>
