import { Inject, Injectable, Optional } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ResolveEnd,
  Router,
} from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { CookiesGroups } from '@altack/ngx-onetrust';
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

import { CustomProps } from './application-insights.models';
import {
  APPLICATION_INSIGHTS_CONFIG,
  ApplicationInsightsModuleConfig,
} from './application-insights-module-config';
import { AI_COOKIES } from './cookie-groups';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private readonly appInsights!: ApplicationInsights;
  private readonly destroy$ = new Subject<void>();

  public constructor(
    @Optional()
    @Inject(APPLICATION_INSIGHTS_CONFIG)
    private readonly moduleConfig: ApplicationInsightsModuleConfig,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    if (this.moduleConfig) {
      this.appInsights = new ApplicationInsights({
        config: {
          ...this.moduleConfig.applicationInsightsConfig,
        },
      });

      if (!moduleConfig.consent) {
        this.appInsights.loadAppInsights();
        this.startTracking(false);
      }
    }
  }

  private static getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    return snapshot.firstChild !== null && snapshot.firstChild !== undefined
      ? ApplicationInsightsService.getActivatedComponent(snapshot.firstChild)
      : snapshot.component;
  }

  public initTracking(
    consentChanged$: Observable<Map<CookiesGroups, boolean>>,
    customProps?: CustomProps
  ): void {
    let first = true;

    consentChanged$.subscribe((cookiesGroups) => {
      const trackingAllowed = cookiesGroups.get(
        CookiesGroups.PerformanceCookies
      );

      if (trackingAllowed) {
        this.startTracking(true);
        if (customProps) {
          const { tag, value } = customProps;
          this.addCustomPropertyToTelemetryData(tag, value);
        }
        this.trackInitalPageView();
      } else if (!first && !trackingAllowed) {
        // only when opting out after consent was given before
        this.deleteCookies();
      }

      first = false;
    });
  }

  public startTracking(cookieEnabled: boolean): void {
    if (cookieEnabled) {
      this.appInsights.loadAppInsights();
    }

    // make sure to not subscribe to router twice
    this.destroy$.next();
    this.createRouterSubscription();
  }

  public deleteCookies(): void {
    // delete cookies since app insights only disable usage of them
    AI_COOKIES.forEach((aiCookie) =>
      this.appInsights?.getCookieMgr().del(aiCookie)
    );
    this.appInsights.config.disableTelemetry = true;
  }

  public addCustomPropertyToTelemetryData(tag: string, value: string): void {
    const telemetryInitializer = (envelope: ITelemetryItem) => {
      envelope.data = { ...envelope.data, [tag]: value };
    };
    this.appInsights?.addTelemetryInitializer(telemetryInitializer);
  }

  public logPageView(name?: string, uri?: string): void {
    this.appInsights?.trackPageView({ name, uri });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights?.trackEvent({ name }, properties);
  }

  public logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights?.trackException({ exception, severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights?.trackTrace({ message }, properties);
  }

  public trackInitalPageView(): void {
    this.trackPageView(this.route.snapshot, this.router.url);
  }

  private createRouterSubscription(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof ResolveEnd),
        map((event) => event as ResolveEnd),
        tap((event: ResolveEnd) =>
          this.trackPageView(event.state.root, event.urlAfterRedirects)
        )
      )
      .subscribe();
  }

  private trackPageView(snapshot: ActivatedRouteSnapshot, uri?: string) {
    // only use the path withouth query parameters as a name
    let name = uri?.split('?')[0];

    if (!this.moduleConfig.trackPageViewUsingUriAsName) {
      name =
        ApplicationInsightsService.getActivatedComponent(snapshot)?.name ??
        name;
    }

    this.logPageView(name, uri);
  }
}
