<div *transloco="let t; read: 'userSettings'">
  <div class="mb-4 text-subtitle-1 text-medium-emphasis">
    {{ t('language.title.main') }}
  </div>
  <schaeffler-language-select class="mb-3 block"></schaeffler-language-select>
  <schaeffler-locale-select
    class="pt-2 sm:pt-0"
    [availableLocales]="availableLocales"
    [defaultLocale]="defaultLocale"
  ></schaeffler-locale-select>
  <mat-divider class="!my-4"></mat-divider>
  <div class="mb-1 text-subtitle-1 text-medium-emphasis">
    {{ t('measurementUnits.title.main') }}
  </div>
  <div class="mb-4 text-caption text-medium-emphasis">
    {{ t('measurementUnits.title.sub') }}
  </div>
  <ga-measurement-units-select></ga-measurement-units-select>
</div>
